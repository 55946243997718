import React from 'react';
import { TextField, FormGroup, FormControlLabel, Checkbox, Card, CardContent, formControlClasses } from '@mui/material';

const FarmacologiaComponent = ({ formData, handleChange }) => {

    const handleTimeChange = (e) => {
        const { name, value } = e.target;

        // Format the time to hh:mm
        const formattedTime = value.slice(0, 5); // Extract first 5 characters (hh:mm)

        handleChange({
            target: {
                name,
                value: formattedTime, // Set the formatted time
            },
        });
    };

    return (
        <table style={{ ...styles.table, marginTop: '10px' }}>
            <thead>
                <tr>
                    <th style={styles.th}>Farmacologia</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="5">
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.th_1}>Hora</th>
                                    <th style={{ ...styles.th_1, width: '50%' }}>Farmaco</th> {/* Larger width */}
                                    <th style={styles.th_1}>Dose</th>
                                    <th style={styles.th_1}>Via</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_hora"
                                            variant="outlined"
                                            fullWidth
                                            type="time" // Use time input type
                                            value={formData.farmacologia_hora || ''} // Set default value
                                            onChange={handleTimeChange} // Use custom handler
                                        />
                                    </td>
                                    <td style={{ ...styles.td, width: '50%' }}> {/* Double-sized cell */}
                                        <TextField
                                            name="farmacologia_farmaco"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_farmaco}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_dose"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_dose}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_via"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_via}
                                            onChange={handleChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_hora1"
                                            variant="outlined"
                                            fullWidth
                                            type="time" // Use time input type
                                            value={formData.farmacologia_hora1 || ''} // Set default value
                                            onChange={handleTimeChange} // Use custom handler
                                        />
                                    </td>
                                    <td style={{ ...styles.td, width: '50%' }}> {/* Double-sized cell */}
                                        <TextField
                                            name="farmacologia_farmaco1"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_farmaco1}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_dose1"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_dose1}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_via1"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_via1}
                                            onChange={handleChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_hora2"
                                            variant="outlined"
                                            fullWidth
                                            type="time" // Use time input type
                                            value={formData.farmacologia_hora2 || ''} // Set default value
                                            onChange={handleTimeChange} // Use custom handler
                                        />
                                    </td>
                                    <td style={{ ...styles.td, width: '50%' }}> {/* Double-sized cell */}
                                        <TextField
                                            name="farmacologia_farmaco2"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_farmaco2}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_dose2"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_dose2}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_via2"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_via2}
                                            onChange={handleChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_hora3"
                                            variant="outlined"
                                            fullWidth
                                            type="time" // Use time input type
                                            value={formData.farmacologia_hora3 || ''} // Set default value
                                            onChange={handleTimeChange} // Use custom handler
                                        />
                                    </td>
                                    <td style={{ ...styles.td, width: '50%' }}> {/* Double-sized cell */}
                                        <TextField
                                            name="farmacologia_farmaco3"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_farmaco3}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_dose3"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_dose3}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_via3"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_via3}
                                            onChange={handleChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_hora4"
                                            variant="outlined"
                                            fullWidth
                                            type="time" // Use time input type
                                            value={formData.farmacologia_hora4 || ''} // Set default value
                                            onChange={handleTimeChange} // Use custom handler
                                        />
                                    </td>
                                    <td style={{ ...styles.td, width: '50%' }}> {/* Double-sized cell */}
                                        <TextField
                                            name="farmacologia_farmaco4"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_farmaco4}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_dose4"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_dose4}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <TextField
                                            name="farmacologia_via4"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.farmacologia_via4}
                                            onChange={handleChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const styles = {
    container: {
        marginLeft: 15,
        marginRight: 15,
        flex: 1,
        backgroundColor: 'white',
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 15,
        paddingRight: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    rowInfo: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 15,
        justifyContent: 'space-between',
        width: '100%',
    },
    inputGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Ensure the label and input are vertically aligned
        flex: 1, // Let it grow to fill available space (you can adjust the flex value for custom widths)
        gap: '10px', // Optional gap between label and input
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: 15,
    },
    infoProp: {
        fontSize: 18,
        paddingBottom: 10,
        textAlign: "left",
        fontWeight: "bold",
        marginBottom: 5,
    },
    input: {
        height: 50,
        width: '100%',
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 10,
        paddingHorizontal: 10,
        marginTop: 5,
        marginBottom: 15
    },
    rowButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 25,
    },
    button_SAVE: {
        width: "100%",
        height: 75,
        backgroundColor: '#99FF99',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
    },
    buttonText: {
        color: '#000000',
        fontSize: 16,
        fontWeight: 'bold',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        backgroundColor: '#0065ad', // Blue color for headers
        color: 'white',
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'center',
        fontSize: '12px',
    },
    th_1: {
        backgroundColor: '#3F9CFA', // Blue color for headers
        color: 'white',
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'center',
        fontSize: '12px',
    },
    td: {
        //backgroundColor: '#e6f0ff', // Light background for cells
        padding: '5px',
        border: '1px solid #ddd',
        textAlign: 'center',
        fontSize: '12px',
    },
    tdBlank: {
        backgroundColor: '#f7faff', // Slightly lighter background for blank cells
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'center',
        fontSize: '12px',
    },
    formContainer: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    formSection: {
        marginBottom: '20px',
    },
    sectionTitle: {
        marginBottom: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '16px',
    },
    checkboxSection: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '4px',
        marginBottom: '20px',
        backgroundColor: '#f9f9f9',
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
    },
    checkboxInput: {
        marginRight: '0px',
    },
    bodyDiagramContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ccc',
        height: '250px',
        marginTop: '10px',
    },
    formField: {
        marginBottom: '10px',
    },
    medicationSection: {
        marginTop: '20px',
    },
    medicationFields: {
        display: 'flex',
        gap: '15px',
        marginTop: '10px',
    },
    formControlCheckbox: {
        paddingLeft: '20px'
    },
    tableContainer: {
        border: '1px solid #ccc',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        marginTop: '20px',
    },
    '@media (max-width: 768px)': {
        medicationFields: {
            flexDirection: 'column',
        },
    },
};

export default FarmacologiaComponent;